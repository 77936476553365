import React, { Component, useContext, useEffect, useRef } from 'react'
import OrbitInputField from '@kiwicom/orbit-components/lib/InputField'

import { useFormikContext } from 'formik'
import { useModelContext } from './ModelFormik'

const TextInputField = ({ placeholder, label, name, ...props }) => {
  const { values, handleBlur, handleChange, errors, touched } = useFormikContext()


  // console.log('values[name]', name, values[name], innerKey)

  // this is important - when null / undefined, input wont reset, therefore fallback to empty string
  const value = values[name] || ''

  const fieldTouched = touched[name]

  // console.log('prpropspropspropsops', props)

  return (
    // <div> // todo má ten div nějaký význam?
    <OrbitInputField
      {...props}
      label={label}
      error={fieldTouched && errors[name]}
      value={value}
      onBlur={handleBlur(name)}
      onChange={handleChange(name)}
      placeholder={placeholder}
      name={name}
    />
    // </div>
  )
}

export default TextInputField
