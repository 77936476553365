import React, { useRef, useState } from 'react'
import { useFormikContext } from 'formik'
import Select from 'react-select'
import { theme } from 'src/styles'
import Label from './Label'
import FieldError from './FieldError'
import { nanoid } from 'nanoid'

const ReactSelectField = ({ placeholder, isMulti, label, options, name, required, ...props }) => {
  const { values, handleBlur, handleChange, errors, touched, setFieldValue } = useFormikContext()

  // this is important - when null / undefined, input wont reset, therefore fallback to empty string
  const value = values[name] || ''

  const fieldTouched = touched[name]

  const onChange = option => {
    setFieldValue(name, option)
  }

  const showErrored = fieldTouched && errors[name]

  return (
    <ReactSelect
      error={errors[name]}
      options={options}
      isMulti={isMulti}
      onBlur={handleBlur(name)}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      label={label}
      showErrored={showErrored}
    />
  )
}

export default ReactSelectField

export const ReactSelect = ({
  required,
  value,
  options,
  isMulti,
  showErrored,
  onBlur,
  onChange,
  placeholder = 'Vyberte...',
  name,
  label,
  error,
  id,
  ...props
}) => {
  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter(option => value.indexOf(option.value) >= 0)
        : options.find(option => option.value === value)
    }
    return isMulti ? [] : ''
  }

  const handleChange = option => onChange(isMulti ? option.map(item => item.value) : option.value)

  // const idRef = useRef(id || `rs-autoid-${nanoid(5)}`)
  const finalId = id || `rs-autoid-${name}`

  return (
    <label>
      <Label
        required={required}
        isFilled={value && Array.isArray(value) ? value.length > 0 : value}
      >
        {label}
      </Label>
      <Select
        {...props}
        styles={{
          control: styles => ({
            ...styles,
            borderColor: showErrored ? theme.colors.redNormal : styles.borderColor,
          }),
          menu: styles => ({
            ...styles,
            zIndex: 10,
          }),
        }}
        options={options}
        isMulti={isMulti}
        error={showErrored}
        onBlur={onBlur}
        value={getValue()}
        onChange={handleChange}
        placeholder={placeholder}
        required={required}
        id={finalId}
        instanceId={finalId}

        // menuIsOpen
      />
      {showErrored && <FieldError>{error}</FieldError>}
    </label>
  )
}
