import React, { useCallback } from 'react'
import { useDrag, useDrop } from 'react-dnd'

const DD = () => null

const DD_TYPE = 'SORTABLE_IMAGE'

const DroppableArea = ({ index, onDrop, ...props }) => {
  const [{ opacity }, drop] = useDrop(
    () => ({
      accept: DD_TYPE,
      drop: item => onDrop(item?.index, index),
      collect: monitor => ({
        opacity: monitor.isOver() ? 0.5 : 1,
        // itemIndex: monitor.getItem(),
      }),
    }),
    [index],
  )

  return <div ref={drop} style={{ opacity }} {...props} />
}

const Sortable = ({ index, ...props }) => {
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: DD_TYPE,
      item: { index },
      collect: monitor => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
    }),
    [],
  )
  return <div ref={dragRef} style={{ opacity }} {...props} />
}

DD.Drop = DroppableArea
DD.Sortable = Sortable

export default DD
