import React from 'react'
import styled, { css } from 'styled-components'

const StyledError = styled.span`
  ${({ theme: { colors }, isFilled }) => css`
    color: ${colors.redNormal};
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    width: 100%;
    max-height: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    margin-top: 8px;
  `}
`

function FieldError({ children, ...props }) {
  return <StyledError {...props}>{children}</StyledError>
}

export default FieldError
