import React from 'react'
import styled, { css } from 'styled-components'

const StyledLabel = styled.span`
  ${({ theme: { colors }, isFilled }) => css`
    font-size: 14px;
    color: ${isFilled ? colors.formLabelFilled : colors.formLabel};
    line-height: 16px;
    margin-bottom: 4px;
    display: block;
  `}
`
const RequiredStar = styled.span`
  ${({ theme: { colors }, isFilled }) => css`
    color: ${isFilled ? colors.formLabelFilled : colors.redNormal};
  `}
`

function Label({ children, required, isFilled, ...props }) {
  // console.log('props', props)
  return (
    <StyledLabel isFilled={isFilled} {...props}>
      {required ? <RequiredStar isFilled={isFilled}>* </RequiredStar> : ''}
      {children}
    </StyledLabel>
  )
}

export default Label
