import React, { Component, useContext, useEffect, useRef } from 'react'
import OrbitInputField from '@kiwicom/orbit-components/lib/InputField'

import { useFormikContext } from 'formik'
import { format as formatDate, parseISO } from 'date-fns'
import { useModelContext } from './ModelFormik'

const TextInputField = ({ placeholder, label, name, ...props }) => {
  const { values, handleBlur, handleChange, errors, touched } = useFormikContext()
  // todo - custom datepicker, pokud je třeba

  // todo - vyřešit
  // this is important - when null / undefined, input wont reset, therefore fallback to empty string
  const value = values[name] || ''
  const formattedValueForPicker = value && formatDate(parseISO(value), 'yyyy-MM-dd')

  const fieldTouched = touched[name]
  return (
    <OrbitInputField
      label={label}
      error={fieldTouched && errors[name]}
      value={formattedValueForPicker}
      onBlur={handleBlur(name)}
      onChange={handleChange(name)}
      // placeholder={placeholder}
      placeholder="random"
      type="date"
      {...props}
    />
  )
}

export default TextInputField
