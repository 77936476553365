import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { nanoid } from 'nanoid'
import { useAuth } from 'lib/auth/useAuth'
import useFileUploader from './useFileUploader'

// todo duplikace
const wrapperBase = css`
  ${({ theme: { orbit }, withPadding, size = '234px' }) => css`
    width: ${size};
    height: ${size};
    border-radius: ${orbit.borderRadiusLarge};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${withPadding ? '16px' : 0};
    text-align: center;
    background: ${orbit.paletteCloudLight};
  `}
`

const DropperWrapper = styled.div`
  ${wrapperBase};
  border: 1px dashed #5f738c;
  cursor: pointer;
`

const DropUpload = ({
  uploadPath,
  setFiles,
  onSuccess,
  isSingleFile,
  placeholderContent,
  size,
  ...props
}) => {
  const { onTokenExpired, tokens } = useAuth()

  const { mutate: uploadFile, isSuccess: uploadSuccess } = useFileUploader({
    auth: { onTokenExpired, tokens },
    uploadPath,
    onSuccess,
  })

  const onDrop = useCallback(
    selectedFiles => {
      if (isSingleFile) selectedFiles = [selectedFiles[0]]
      selectedFiles.forEach(file => {
        const reader = new FileReader()
        const tempId = nanoid(6)
        const newFile = {
          file,
          isUploading: true,
          tempId,
        }

        if (isSingleFile) {
          setFiles(newFile)
        } else {
          setFiles(files => [...files, newFile])
        }

        uploadFile({ file, tempId })
        reader.onload = () => {
          // todo - tady jen nastavit urlSrc -
          // a vlastně to ani není třeba a můžeme pak brát url rovno na to co se nahraje
          setFiles(files =>
            !isSingleFile
              ? files.map(file => {
                if (file.tempId === tempId) return { ...file, urlSrc: reader.result }
                return file
              })
              : { ...files, urlSrc: reader.result })
        }
        reader.onerror = () => console.log('Image upload failed') // todo
        reader.readAsDataURL(file)
      })
    },
    [uploadFile, setFiles, isSingleFile],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpg', '.jpeg', '.png'],
    },
  })
  return (
    <DropperWrapper {...getRootProps()} size={size} withPadding={!placeholderContent}>
      <input {...getInputProps()} />
      {isDragActive
        ? <p>Přetáhněte soubory zde...</p>
        : placeholderContent || (
          <div>
            <p style={{ textDecoration: 'underline', fontWeight: 700, margin: '8px' }}>
              {isSingleFile ? 'Vyberte fotografii' : 'Vyberte fotografie'}
            </p>
            <p>Nebo přetáhněte {isSingleFile ? 'fotografii' : 'fotografie'} do tohoto pole</p>
            {/* <p><i>.jpg, .png</i></p> */}
          </div>
        )}
    </DropperWrapper>
  )
}

export default DropUpload
