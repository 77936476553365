import React, { useCallback, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useDropzone } from 'react-dropzone'
import Inline from '@kiwicom/orbit-components/lib/Inline'
import Icon from 'src/components/Icon'
import Row from 'lib/components/Row'
import { useFormikContext } from 'formik'
import Col from 'lib/components/Col'
import { Button } from '@kiwicom/orbit-components'
import Gap from 'lib/components/Gap'
import DropUpload from './DropUpload'
import DD from './DD'

// todo duplikace
const wrapperBase = css`
  ${({ theme: { orbit }, withPadding }) => css`
    width: 234px;
    height: 234px;
    border-radius: ${orbit.borderRadiusLarge};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${withPadding ? '16px' : 0};
    text-align: center;
    background: ${orbit.paletteCloudLight};
  `}
`

const ImageWrapper = styled.div`
  ${({ theme: { orbit }, size }) => css`
    ${wrapperBase};
    width: ${size};
    height: ${size};
    background: white;
    box-shadow: ${orbit.boxShadowAction};
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    img {
      max-width: 100%;
      max-height: 156px;
      margin: auto;
    }
  `}
`

const SingleImageWrapper = styled.div`
  ${({ theme: { orbit }, size }) => css`
    position: relative;
    font-size: 0;
    img {
      max-width: 100%;
      max-height: ${size};
      margin: auto;
    }
  `}
`

const ControlsRow = styled(Row)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* padding: 10px; */
`
const Clickable = styled.div`
  padding: 10px;
  cursor: pointer;
`

// todo - počkat až se soubor skutečně nahraje, aby nedošlo k uložení nabídky bez obrázků
// todo - refactor

const moveItem = ({ items, fromIndex, toIndex }) => {
  const movedItem = items[fromIndex]

  const isForward = toIndex < fromIndex

  let newArray = [...items]
  newArray[fromIndex] = null

  const splitIndex = isForward ? toIndex : toIndex + 1
  const firstPart = newArray.slice(0, splitIndex)
  const secondPart = newArray.slice(splitIndex)

  newArray = [...firstPart, movedItem, ...secondPart]
  newArray = newArray.filter(item => !!item)

  return newArray
}

const FileSelector = ({
  name,
  uploadPath,
  isSingleFile,
  imagesLabel = 'Fotografie',
  thumbSize = '234px',
}) => {
  const [files, setFiles] = useState([])

  const { values, setFieldValue } = useFormikContext()
  // // set initial state
  useEffect(() => {
    const galleryValue = values[name] || []
    setFiles(isSingleFile ? [galleryValue] : [...galleryValue])
  }, [])

  // set formik value when state changes
  useEffect(() => {
    setFieldValue(
      name,
      files.map(({ _id }) => _id),
    )
  }, [files, name, setFieldValue])

  const onUploadSuccess = useCallback(
    (res, tempId) => {
      setFiles(files =>
        files.map(file => {
          if (file.tempId === tempId) {
            return {
              ...file,
              isUploading: false,
              _id: res._id,
            }
          }
          return file
        }))
    },
    [setFiles],
  )

  // todo skutečně smazat
  const onDeleteFile = useCallback(
    index => {
      setFiles(files => files.filter((f, key) => key !== index))
    },
    [setFiles],
  )

  const sortImages = useCallback(
    (fromIndex, toIndex) => {
      setFiles(files => moveItem({ items: files, fromIndex, toIndex }))
    },
    [setFiles],
  )

  return (
    <Inline spacing="medium">
      <DropUpload
        uploadPath={uploadPath}
        setFiles={setFiles}
        onSuccess={onUploadSuccess}
        isSingleFile={isSingleFile}
      />

      {files?.map((file, key) => (
        // zde záměrně bez key - jinak to nefunguje
        <DD.Drop index={key} onDrop={sortImages}>
          <DD.Sortable index={key}>
            <ImageWrapper size={thumbSize}>
              <ControlsRow>
                <Clickable>
                  {!isSingleFile && `${key + 1}.`} {imagesLabel}
                </Clickable>
                <Clickable>
                  <Icon.Move />
                </Clickable>
              </ControlsRow>
              <img src={file?.filePath || file?.urlSrc} alt="" />
              <ControlsRow width="100%" justifyContent="flex-end">
                <Clickable onClick={() => onDeleteFile(key)}>
                  <Icon.Trash color="#F95159" />
                </Clickable>
              </ControlsRow>
            </ImageWrapper>
          </DD.Sortable>
        </DD.Drop>
      ))}
    </Inline>
  )
}

FileSelector.SingleFile = () => null

const SingleImage = ({ name, uploadPath, isSingleFile }) => {
  const { values, setFieldValue } = useFormikContext()
  // // set initial state
  // useEffect(() => {
  //   const galleryValue = values[name] || []
  //   setFiles(isSingleFile ? [galleryValue] : [...galleryValue])
  // }, [])

  const [file, setFile] = useState(values[name] || null)

  // set formik value when state changes
  useEffect(() => {
    setFieldValue(name, file?._id)

    console.log('file updated!!', file)
  }, [file, name, setFieldValue])

  const onUploadSuccess = useCallback(
    (res, tempId) => {
      console.log('uplod succ', res, tempId)
      setFile({
        ...file,
        isUploading: false,
        _id: res._id,
        filePath: res.filePath,
      })
    },
    [setFile, file],
  )

  // TODO: mazat ze serveru
  const onDeleteFile = useCallback(() => {
    setFile(null)
  }, [setFile])


  // console.log('filefilefile', file)
  const placeholderContent = file && (
    <SingleImageWrapper size="300px">
      <img src={file?.filePath || file?.urlSrc} alt="" />
    </SingleImageWrapper>
  )

  return (
    <Col maxWidth="300px">
      <DropUpload
        uploadPath={uploadPath}
        setFiles={setFile}
        onSuccess={onUploadSuccess}
        isSingleFile={isSingleFile}
        placeholderContent={placeholderContent}
        size="300px"
      />

      <Gap gap="16px" />

      <div>
        <Button fullWidth type="critical" onClick={() => onDeleteFile()}>
          Smazat
        </Button>
      </div>
    </Col>
  )
}

FileSelector.SingleImage = SingleImage

export default FileSelector
