import React, { useState } from 'react'
import OrbitSelect from '@kiwicom/orbit-components/lib/Select'
import { useFormikContext } from 'formik'

const SelectField = ({ placeholder, label, options, name, ...props }) => {
  const { values, handleBlur, handleChange, errors, touched } = useFormikContext()

  // this is important - when null / undefined, input wont reset, therefore fallback to empty string
  const value = values[name] || ''

  const fieldTouched = touched[name]
  return (
    <div>
      <OrbitSelect
        {...props}
        options={options}
        label={label}
        error={fieldTouched && errors[name]}
        value={value}
        onBlur={handleBlur(name)}
        onChange={handleChange(name)}
        placeholder={placeholder}
      />
    </div>
  )
}

export default SelectField

export const SelectWithState = ({ options, onChange, ...props }) => {
  const [value, setValue] = useState(options[0]?.value)

  return (
    <OrbitSelect
      {...props}
      value={value}
      options={options}
      onChange={e => {
        setValue(e.target.value)
        onChange?.(e.target.value)
      }}
    />
  )
}
