// ! high level abstraction for all fields

import React, { Component, useContext, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import Row from 'lib/components/Row'
import Gap from 'lib/components/Gap'
import { shouldBeShown, shouldBeRequired } from 'lib/models/safe'
import { useFormikContext } from 'formik'
import ExMatch from 'expression-match'
import Separator from '@kiwicom/orbit-components/lib/Separator'
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip'
import Icon from 'src/components/Icon'
import TextInputField from './TextInputField'
import TextAreaField from './TextAreaField'
import SelectField from './SelectField'
import BetterText from '../BetterText'
import RadioSelectField from './RadioSelectField'
import CheckBoxGroupField from './CheckBoxGroupField'
import DateField from './DateField'
import CheckBoxField from './CheckBoxField'
import FileSelector from '../FileSelector'
import { useModelContext } from './ModelFormik'
import ReactSelectField from './ReactSelectField'

export const typeToComponentMap = {
  Text: TextInputField,
  // TODO: customizovat - asi se to da resit nejakym tenkym obalem
  Number: TextInputField,
  Email: TextInputField,
  Date: DateField,
  // TODO:
  Textarea: TextAreaField,
  // Boolean: false,
  // Relationship: null,
  Select: SelectField,
  TextArray: CheckBoxGroupField,
  Boolean: CheckBoxField,
  // Password: TextInputField,
}

export const typeToInputTypeMap = {
  Text: 'text',
  Number: 'number',
  Email: 'email',
  Date: 'date',
  // Textarea: TextAreaField,
  // Relationship: null,
  // Select: SelectField,
  // TextArray: CheckBoxGroupField,
  Boolean: 'checkbox',
  Password: 'password',
}

const FieldWrapper = styled.div`
  ${({ maxWidth = '100%', width }) => css`
    max-width: ${maxWidth};
    width: ${width};
    /* width: 100%; */
  `}
`

// const SideLabelWrapper = styled.div`
//   height: 100%;
//   display: flex;
//   flex-direction: row;
//   justify-content: stretch;
//   align-items: stretch;
//   /* align-items: center; */
//   width: 191px; // 215 - 24 padding boxu
//   flex-shrink: 0;
//   padding-right: 24px;
//   /* align-items: center; */
// `
// const OfferFormRow = styled.div`
//   ${({ align = 'center' }) => css`
//     position: relative;
//     display: flex;
//     width: 100%;
//     flex-direction: row;
//     /* min-height: 50px; */
//     align-items: ${align};
//   `}
// `

const WIDTHS = {
  normal: '296px',
  large: '592px',
}
// const SectionSeparator = () => (
//   <div style={{ zIndex: 100, position: 'relative' }}>
//     <Gap gap="18px" />
//     <Separator />
//     <Gap gap="18px" />
//   </div>
// )

const ModelField = ({
  // hasSideLabel,
  width,
  maxWidth = '100%',
  EnforcedComponent,
  withoutLabel = false,
  align = 'center',
  label,
  fieldConfigName,
  alwaysShown, // bypass shouldShow / dependsOn
  options,
  append,
  ...props
}) => {
  const { name } = props

  const { model, disableAll } = useModelContext()
  const { values } = useFormikContext()

  const field = model.fields[fieldConfigName || name]

  if (!field) {
    return <div>field {name} is not defined in model </div>
  }
  if (!label) label = field.label
  const { type, placeholder, getUnit } = field
  let { unit } = field
  if (getUnit) unit = getUnit(values)
  let Component = typeToComponentMap[type] || TextInputField
  const inputType = typeToInputTypeMap[type]

  const settings = {
    placeholder: placeholder || label,
    options: options || field.options,
  }
  // todo - je toto potřeba kontrolovat?
  // Sice se jinak může stát že někam pošlu options navíc, ale to by snad nemělo vadit
  // zatim tedy options předány vždy

  if (type === 'Select' || type === 'TextArray') {
    // settings.options = field.options
    if (field.isRadioSelect) {
      Component = RadioSelectField
    }
    if (field.isMultiSelect) {
      Component = ReactSelectField
      settings.isMulti = true
    }
  }

  if (!width) {
    if (field.isRadioSelect) width = '100%'
    if (type === 'TextArray') width = '100%'
    if (!width) width = 'normal'
  }

  const shouldShow = shouldBeShown(field, values) || alwaysShown

  // TODO: tady muze byt treba hook, ktery na zmenu shouldShow pole rawQuerysmaze
  // TODO: minimalne by to tak melo byt pri odeslani nebo pak pri ukladani do DB na strane API!!

  const required = shouldBeRequired(field, values)

  // console.log('settingssettings', name, settings, type)
  // console.log('required', required, name)

  if (EnforcedComponent) {
    Component = EnforcedComponent
  }

  // if (hasSideLabel) {
  //   return (
  //     shouldShow && (
  //       <OfferFormRowWrapper
  //         label={label}
  //         unit={unit}
  //         width={width}
  //         maxWidth={maxWidth}
  //         withoutLabel={withoutLabel}
  //         align={align}
  //         required={required}
  //         type={inputType}
  //         {...props}
  //       >
  //         <Component required={required} {...settings} {...props} />
  //       </OfferFormRowWrapper>
  //     )
  //   )
  // }

  return (
    shouldShow && (
      <Row alignItems="flex-end" maxWidth="100%">
        <FieldWrapper width={WIDTHS[width] || width} maxWidth={maxWidth}>
          <Component
            type={inputType}
            required={required}
            label={!withoutLabel && (label || field.label || name)}
            {...settings}
            {...props}
          />
          {append}
        </FieldWrapper>
        {unit && (
          <>
            <Gap gap="10px" />
            <BetterText color="textDark" fontSize="14px" lineHeight="44px">
              {unit}
            </BetterText>
          </>
        )}
      </Row>
    )
  )
}

//  TODO: prejmenovat na neco univerzalniho
// const OfferFormRowWrapper = ({
//   label,
//   width = '100%',
//   maxWidth = '100%',
//   children,
//   unit,
//   align = 'start',
//   withoutSeparator = false,
//   name, // todo nastavit dle fieldu
//   required,
// }) => {
//   const { model } = useModelContext()
//   const { values } = useFormikContext()

//   const field = model.fields[name]
//   // if (!field) return null
//   if (field) {
//     if (!label) label = field.label
//     if (!shouldBeShown(field, values)) return null
//   }
//   // if (!required) required = shouldBeRequired(field, values)
//   return (
//     <div>
//       <OfferFormRow align={align}>
//         <SideLabelWrapper>
//           {label && (
//             <BetterText fontSize="16px" fontWeight="500">
//               {label}
//             </BetterText>
//           )}
//           {field && shouldBeRequired(field, values) && required !== false &&
//             <BetterText color="#F95159">*</BetterText>}
//           {field?.tooltip && (
//             <Tooltip content={field.tooltip}>
//               <Gap gap="8px" />
//               <Icon.Help />
//               <Gap gap="8px" />
//             </Tooltip>
//           )}
//         </SideLabelWrapper>

//         <Gap gap="24px" />
//         <FieldWrapper width={WIDTHS[width] || width} maxWidth={maxWidth}>
//           {children}
//         </FieldWrapper>

//         {unit && (
//           <>
//             <Gap gap="10px" />
//             <BetterText color="textDark" fontSize="14px" lineHeight="44px">
//               {unit}
//             </BetterText>
//           </>
//         )}
//       </OfferFormRow>
//       {!withoutSeparator && <SectionSeparator />}
//     </div>
//   )
// }

// ModelField.FormRow = OfferFormRowWrapper

export default ModelField
