import { useMutation } from 'react-query'
import { createApiCall } from 'lib/queries'

const uploadFileToServer = async ({ file, tempId, setCompleted, onSuccess, auth, uploadPath }) => {
  // todo předat do axiosu a dořešit progress- ale není nutné to dělat teď
  // const config = {
  //   onUploadProgress(progressEvent) {
  //     const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
  //     setCompleted(percentCompleted)
  //   },
  // }
  const path = uploadPath
  const data = new FormData()
  data.append('file', file)

  const res = await createApiCall({ data, path, method: 'POST', ...auth })()
  return onSuccess(res, tempId)
}

const useFileUploader = ({ auth, uploadPath, setCompleted, onSuccess }) =>
  useMutation(
    ({ file, setCompleted, tempId }) =>
      uploadFileToServer({ file, tempId, setCompleted, onSuccess, auth, uploadPath }),
    {
      onError: e => {
        console.log('upload error', e.message)
      },
      onSuccess: () => {
        console.log('file uploaded successfully')
      },
    },
  )

export default useFileUploader
