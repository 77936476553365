import React from 'react'
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox'
import ChoiceGroup from '@kiwicom/orbit-components/lib/ChoiceGroup'
import Stack from '@kiwicom/orbit-components/lib/Stack'
import Inline from '@kiwicom/orbit-components/lib/Inline'
import { useFormikContext } from 'formik'
import InputGroup from '@kiwicom/orbit-components/lib/InputGroup'
import styled, { css } from 'styled-components'

// s orbit gridem to nefuguje
const CustomGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
  width: 100%;
`

const CheckBoxField = ({ label, options, name, ...props }) => {
  const { values, handleBlur, handleChange, errors, touched, setFieldValue } = useFormikContext()

  const value = values[name] || ''

  const fieldTouched = touched[name]

  // const onChange = (value) => {
  //   console.log('!!!!!!', value.target.value)
  //   handleChange(name)(value)
  // }

  return (
    <Checkbox
      {...props}
      label={label}
      value={value}
      hasError={fieldTouched && errors[name]}
      onChange={handleChange(name)}
      // onChange={onChange}
      onBlur={handleBlur(name)}
      checked={value}
    />
  )
}

export default CheckBoxField
