import React from 'react'
import Radio from '@kiwicom/orbit-components/lib/Radio'
import ChoiceGroup from '@kiwicom/orbit-components/lib/ChoiceGroup'
import Stack from '@kiwicom/orbit-components/lib/Stack'
import { useFormikContext } from 'formik'
import InputGroup from '@kiwicom/orbit-components/lib/InputGroup'
import styled, { css } from 'styled-components'

const RadioSelectField = ({ label, options, name, colsTemplate, ...props }) => {
  const { values, handleBlur, handleChange, errors, touched, setFieldValue } = useFormikContext()

  const selectedValue = values[name]

  const fieldTouched = touched[name]

  // TODO - pokud se do choice group dá stack, tak to přestane fungovat
  // tedy až to budeme chtít dát do řádku, tak se musí dát pryč choiceGroup
  // a logiku (errory) udělat separátně

  // TODO - choice group nemá required parametr

  // todo ale s custom gridem to funguje? :D
  // todo - nastavit grid lépe
  const CustomGrid = styled.div`
    ${({ colsTemplate = '1fr 1fr 1fr 1fr 1fr' }) => css`
      display: grid;
      grid-template-columns: ${colsTemplate};
      gap: 12px;
      width: 100%;
    `}
  `

  return (
    <ChoiceGroup
      label={label}
      error={fieldTouched && errors[name]}
      onChange={handleChange(name)}
      onBlur={handleBlur(name)}
      {...props}
    >
      {/* <Stack direction="row"> */}
      <CustomGrid colsTemplate={colsTemplate}>
        {options.map(({ label, value }) => (
          <Radio
            checked={selectedValue === value}
            key={value}
            label={label}
            name={value}
            value={value}
            onChange={() => {
              // toto se volá jen poku není v choiceGroup
              // setFieldValue(name, value)
            }}
          />
        ))}
      </CustomGrid>
      {/* </Stack> */}
    </ChoiceGroup>
  )
}

export default RadioSelectField
