import React, { useState } from 'react'
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox'
import ChoiceGroup from '@kiwicom/orbit-components/lib/ChoiceGroup'
import Stack from '@kiwicom/orbit-components/lib/Stack'
import Inline from '@kiwicom/orbit-components/lib/Inline'
import { useFormikContext } from 'formik'
import InputGroup from '@kiwicom/orbit-components/lib/InputGroup'
import styled, { css } from 'styled-components'

// s orbit gridem to nefuguje
const CustomGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
  width: 100%;
`

// only presentational component
export const CheckBoxGroup = ({ value, options, ...props }) => (
  <ChoiceGroup {...props}>
    <CustomGrid>
      {options.map(({ label, value: optionValue }) => (
        <Checkbox
          checked={value.includes(optionValue)}
          key={optionValue}
          label={label}
          name={optionValue}
          value={optionValue}
          // TODO: tohle v konzoli vybleje neco o checked nema onChange..
          onChange={() => {}}
        />
      ))}
    </CustomGrid>
  </ChoiceGroup>
)


// sefl managed inner state with callback
export const CheckBoxGroupWithState = ({
  label,
  onChange,
  defaultValue = [],
  options,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue)

  const handleChange = ev => {
    let finalState
    if (value.includes(ev.target.value)) {
      finalState = value.filter(a => a !== ev.target.value)
    } else {
      finalState = [...value, ev.target.value]
    }
    setValue(finalState)
    onChange?.(finalState)
  }

  return (
    <>
      <CheckBoxGroup
        label={label}
        onChange={handleChange}
        options={options}
        value={value}
        {...props}
      />
    </>
  )
}

// component for formik
const CheckBoxGroupField = ({ label, options, name, ...props }) => {
  const { values, handleBlur, handleChange, errors, touched, setFieldValue } = useFormikContext()

  const value = values[name] || []

  const fieldTouched = touched[name]

  return (
    <>
      <CheckBoxGroup
        label={label}
        error={fieldTouched && errors[name]}
        onChange={handleChange(name)}
        onBlur={handleBlur(name)}
        options={options}
        value={value}
        {...props}
      />
    </>
  )
}

// const CheckBoxGroupField = ({ label, options, name, ...props }) => {
//   const { values, handleBlur, handleChange, errors, touched, setFieldValue } = useFormikContext()

//   const selectedValue = values[name] || []

//   const fieldTouched = touched[name]

//   return (
//     <>
//       <ChoiceGroup
//         label={label}
//         error={fieldTouched && errors[name]}
//         onChange={handleChange(name)}
//         onBlur={handleBlur(name)}
//         {...props}
//       >
//         <CustomGrid>
//           {options.map(({ label, value }) => (
//             <Checkbox
//               checked={selectedValue.includes(value)}
//               key={value}
//               label={label}
//               name={value}
//               value={value}
//             />
//           ))}
//         </CustomGrid>
//       </ChoiceGroup>
//     </>
//   )
// }

export default CheckBoxGroupField
