import React from 'react'
import TextArea from '@kiwicom/orbit-components/lib/Textarea'
import { useFormikContext } from 'formik'
import Label from './Label'

const TextInputField = ({ required, placeholder, label, name, rows = 15, ...props }) => {
  const { values, handleBlur, handleChange, errors, touched } = useFormikContext()

  // console.log('values[name]', name, values[name], innerKey)

  // this is important - when null / undefined, input wont reset, therefore fallback to empty string
  const value = values[name] || ''

  const fieldTouched = touched[name]

  return (
    <label>
      <Label required={required} isFilled={!!value}>
        {label}
      </Label>
      <TextArea
        {...props}
        // label={label}
        error={fieldTouched && errors[name]}
        value={value}
        onBlur={handleBlur(name)}
        onChange={handleChange(name)}
        placeholder={placeholder}
        rows={rows}
        required={required}
      />
    </label>
  )
}

export default TextInputField
